<template>
  <nav class="topnav navbar navbar-light">
    <button
      type="button"
      class="navbar-toggler text-muted mt-2 p-0 mr-3 collapseSidebar"
    >
      <i class="fe fe-menu navbar-toggler-icon"></i>
    </button>
    <form class="form-inline mr-auto searchform text-muted">
      <input
        class="form-control mr-sm-2 bg-transparent border-0 pl-4 text-muted"
        type="search"
        placeholder="Type something..."
        aria-label="Search"
      />
    </form>
    <ul class="nav">
      <!-- <li class="nav-item">
        <a
          class="nav-link text-muted my-2"
          href="#"
          id="modeSwitcher"
          data-mode="light"
        >
          <i class="fe fe-sun fe-16"></i>
        </a>
      </li> -->
      <li class="nav-item">
        <a
          class="nav-link text-muted my-2"
          href="/#"
          data-toggle="modal"
          data-target=".modal-shortcut"
        >
          <span class="fe fe-grid fe-16"></span>
        </a>
      </li>
      <!-- <li class="nav-item nav-notif">
        <a
          class="nav-link text-muted my-2"
          href="/#"
          data-toggle="modal"
          data-target=".modal-notif"
        >
          <span class="fe fe-bell fe-16"></span>
          <span class="dot dot-md bg-success"></span>
        </a>
      </li> -->
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle text-muted pr-0"
          href="#"
          id="navbarDropdownMenuLink"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="avatar avatar-sm mt-2">
             <img
                  v-if="user.image"
                  :src="user.image.path"
                  class="avatar-img rounded-circle"
                />
                 <img
                  v-else
                  src="/assets/avatars/default_user.svg"
                  class="avatar-img rounded-circle"
                />
          </span>
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="navbarDropdownMenuLink"
        >
          <router-link class="dropdown-item" to="/profile">Profile</router-link>
          <a class="dropdown-item" href="#" @click="submitLogout">Logout</a>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import $ from "jquery";

export default {
  name: "Navbar",
  created() {},
  mounted() {
    $(".collapseSidebar").on("click", function (e) {
      $(".vertical").hasClass("narrow")
        ? $(".vertical").toggleClass("open")
        : ($(".vertical").toggleClass("collapsed"),
          $(".vertical").hasClass("hover") &&
            $(".vertical").removeClass("hover")),
        e.preventDefault();
    });
  },

  methods: {
    ...mapActions("auth", ["logout"]),

    submitLogout() {
      this.logout(this.credential)
        .then(() => {
          this.$router.replace("/login");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  computed: {
    ...mapGetters("auth", { user: "getUser" }),
  },
};
</script>

<style></style>
