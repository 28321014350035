require('./libs/bootstrap')

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
//Moment JS
import moment from "moment";
window.moment = moment;

Vue.filter("myDate", function (date) {
  return moment(date).format("MMM Do YYYY");
});

Vue.filter("myTime", function (date) {
  return moment(date).format("LTS");
});

Vue.filter("currency", function (amount) {
  let val = "Rs. " + (amount / 1).toFixed(2);
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

Vue.filter("capitalize", function (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
});

Vue.filter("pdate", function (date) {
  return moment(date).format("DD/MM/YYYY");
});

Vue.filter("uppercase", function (string) {
  return string.toUpperCase();
});

Vue.filter("nameStandard", function (words) {
  var separateWord = words.toLowerCase().split(" ");
  for (var i = 0; i < separateWord.length; i++) {
      separateWord[i] =
          separateWord[i].charAt(0).toUpperCase() +
          separateWord[i].substring(1);
  }
  return separateWord.join(" ");
});

Vue.filter("meetingDateTime", function (date) {
  return moment(date).format("MMM Do YYYY hh:mm A");
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
