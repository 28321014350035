<template>
  <div>
    <h3>My Invoices</h3>

    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h5>All Invoices</h5>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Invoice#</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Course</th>
                    <th scope="col">Enrollment Id</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Sys.User</th>
                    <th scope="col">Method</th>
                    <th scope="col">Status</th>
                    <th scope="col">Verified</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody v-if="invoices">
                  <tr v-for="item in invoices.data" :key="item.id">
                    <th scope="row">
                      {{ item.id }}
                    </th>
                    <td>
                      {{ item.due_date | pdate }}
                    </td>
                    <td>
                      {{ item.payable.course.name | nameStandard }}
                    </td>
                    <td>
                      {{ item.payable.code }}
                    </td>
                    <td>
                      {{ item.amount | currency }}
                    </td>
                    <td>
                      <span v-if="item.user">
                        {{ item.user.username | nameStandard }}
                      </span>
                      <span v-else>-- --</span>
                    </td>
                    <td>
                      <div
                        v-if="
                          item.status == 'pending' ||
                          item.status == 'error' ||
                          item.status == 'overdue'
                        "
                      >
                        <select
                          @change="changePaymentType($event, item.id)"
                          class="form-control"
                        >
                          <option
                            :selected="
                              item.type != 'card' || item.type != 'bank'
                            "
                            disabled
                            :value="null"
                          >
                            Select Type
                          </option>
                          <option :selected="item.type == 'card'" value="card">
                            Card
                          </option>
                          <option :selected="item.type == 'bank'" value="bank">
                            Bank
                          </option>
                        </select>
                      </div>
                      <div v-if="item.status == 'paid'">
                        {{ item.type | uppercase }}
                      </div>
                    </td>
                    <td>
                      {{ item.status | uppercase }}
                    </td>
                    <td>
                      <div v-if="item.status == 'paid'">
                        <span
                          v-if="item.verified"
                          class="badge badge-success font-weight-bold p-1 text-white"
                          >Verified</span
                        >
                        <span
                          v-else
                          class="badge badge-warning font-weight-bold p-1 text-white"
                          >Unverified</span
                        >
                      </div>
                      <div v-else>-- --</div>
                    </td>
                    <td class="text-center">
                      <div v-if="item.type == 'bank' || item.type == 'card'">
                        <button
                          @click="payNow(item)"
                          v-if="
                            item.status == 'pending' ||
                            item.status == 'error' ||
                            item.status == 'overdue'
                          "
                          class="btn btn-sm text-white btn-success"
                        >
                          Pay Now
                        </button>
                      </div>
                      <button
                        v-if="item.status == 'paid'"
                        class="btn btn-sm text-white btn-primary"
                      >
                        Download Invoice
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-if="invoices.next_page_url"
              class="row d-flex justify-content-center mt-2"
            >
              <div class="col-6">
                <button
                  @click="loadMoreData(invoices.next_page_url)"
                  class="btn btn-block btn-sm btn-outline-info"
                >
                  Load More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Upload Slip Modal -->
    <div
      class="modal fade"
      ref="paymentModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modelTitleId"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Upload Slip Modal</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <FileDialog
                  :isInvalid="payment.errors.errors.hasOwnProperty('image')"
                  :accept="''"
                  @output="
                    (file) => {
                      this.payment.image = file[0];
                    }
                  "
                  :label="'Upload your proof slip'"
                >
                  <p slot="errorMessage" class="invalid-feedback d-block">
                    Slip Image is required
                  </p>
                </FileDialog>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              @click="uploadPaymentSlip"
              :disabled="payment.busy"
              type="button"
              class="btn btn-primary"
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->
  </div>
</template>

<script>
import FileDialog from "vue-file64";
import Form from "vform";
Form.axios = mainApi;

import $ from "jquery";
import mainApi from "../../api/mainApi";
import swal from "sweetalert2";

export default {
  name: "MyInvoices",

  created() {
    this.getMyInvoices();
  },

  components: {
    FileDialog,
  },

  data() {
    return {
      invoices: {},
      limit: 15,

      payment: new Form({
        id: "",
        type: "",
        image: null,
      }),
    };
  },

  methods: {
    getMyInvoices(page = 1) {
      mainApi
        .get("api/lms/my-invoices", {
          params: {
            limit: this.limit,
            page,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.invoices = response.data;
          }
        })
        .catch((error) => {
          swal.fire({
            title: "Opps...",
            icon: "error",
            text: error.response.data.message,
          });
        });
    },

    loadMoreData(url) {
      mainApi
        .get(url, {
          params: {
            limit: this.limit,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.invoices.data = [...this.invoices.data, ...response.data.data];
            this.invoices.next_page_url = response.data.next_page_url;
          }
        })
        .catch((error) => {
          swal.fire({
            title: "Opps...",
            icon: "error",
            text: error.response.data.message,
          });
        });
    },

    changePaymentType(event, paymentId) {
      mainApi
        .post("api/lms/update-invoice", {
          payment_id: paymentId,
          type: event.target.value,
        })
        .then((response) => {
          if (response.status == 200) {
            this.invoices.data = this.invoices.data.map((x) => {
              if (x.id == response.data.payment.id)
                return response.data.payment;
              return x;
            });

            swal.fire({
              title: "Okay...",
              icon: "success",
              text: response.data.message,
            });
          }
        })
        .catch((error) => {
          swal.fire({
            title: "Opps...",
            icon: "error",
            text: error.response.data.message,
          });
        });
    },

    redirectToIpg({ params, url }) {
      const tempForm = document.createElement("form");
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          const hiddenField = document.createElement("input");
          hiddenField.type = "hidden";
          hiddenField.name = key;
          hiddenField.value = params[key];

          tempForm.appendChild(hiddenField);
        }
      }

      tempForm.method = "post";
      tempForm.action = url;
      document.body.appendChild(tempForm);
      tempForm.submit();
    },

    payNow(invoice) {
      this.payment.type = invoice.type;
      this.payment.id = invoice.id;

      if (invoice.type == "bank") {
        $(this.$refs.paymentModal).modal("show");
      } else if (invoice.type == "card") {
        this.payment
          .post(`/api/lms/make-payment/${this.payment.id}`)
          .catch((error) => {
            if (error.response.status == 303) {
              // Redirect to IPG
              return this.redirectToIpg(error.response.data);
            }

            swal.fire({
              title: "Opps...",
              icon: "error",
              text: error.response.data.message,
            });
          });
      } else {
        swal.fire({
          title: "Opps...",
          icon: "error",
          text: "Invalid type, please update the type and retry",
        });
      }
    },

    uploadPaymentSlip() {
      swal
        .fire({
          title: "Are you sure?",
          text: "You are about to upload the payment proof!",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, upload it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.payment
              .post(`/api/lms/make-payment/${this.payment.id}`)
              .then((response) => {
                if (response.status == 200) {
                  $(this.$refs.paymentModal).modal("hide");
                  this.payment.reset();
                  this.payment.clear();
                  this.invoices.data = this.invoices.data.map((x) => {
                    if (x.id == response.data.payment.id)
                      return response.data.payment;
                    return x;
                  });

                  swal.fire({
                    title: "Okay...",
                    icon: "success",
                    text: response.data.message,
                  });
                }
              })
              .catch((error) => {
                swal.fire({
                  title: "Opps...",
                  icon: "error",
                  text: error.response.data.message,
                });
              });
          }
        });
    },
  },
};
</script>

<style></style>
