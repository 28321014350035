<template>
  <div
    class="modal fade modal-notif modal-slide"
    tabindex="-1"
    role="dialog"
    aria-labelledby="defaultModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="defaultModalLabel">Notifications</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="list-group list-group-flush my-n3">
            <div class="list-group-item bg-transparent">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="fe fe-box fe-24"></span>
                </div>
                <div class="col">
                  <small><strong>Test Notification</strong></small>
                  <div class="my-0 text-muted small">
                    Notification Updated soon
                  </div>
                  <small class="badge badge-pill badge-light text-muted"
                    >1m ago</small
                  >
                </div>
              </div>
            </div>

            <!-- / .row -->
          </div>
          <!-- / .list-group -->
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary btn-block"
            data-dismiss="modal"
          >
            Clear All
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
