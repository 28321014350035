<template>
  <div>
    <h3>Available Courses</h3>
  </div>
</template>

<script>
import mainApi from "../../api/mainApi";
export default {
  created() {
    this.getAvailableCourses();
  },

  data() {
    return {
      courses: null,
    };
  },

  methods: {
    getAvailableCourses() {
      mainApi
        .get("/api/lms/all-available-courses")
        .then((response) => {
          if (response.status == 200) {
            this.courses = response.data;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style></style>
