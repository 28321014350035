<template>
  <aside
    class="sidebar-left border-right bg-white shadow"
    id="leftSidebar"
    data-simplebar
  >
    <a
      href="#"
      class="btn collapseSidebar toggle-btn d-lg-none text-muted ml-2 mt-3"
      data-toggle="toggle"
    >
      <i class="fe fe-x"><span class="sr-only"></span></i>
    </a>
    <nav class="vertnav navbar navbar-light">
      <!-- nav bar -->
      <div class="w-100 mb-4 d-flex">
        <router-link
          class="navbar-brand mx-auto mt-2 flex-fill text-center"
          to="/dashboard"
        >
          <svg
            version="1.1"
            id="logo"
            class="navbar-brand-img brand-sm"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 120 120"
            xml:space="preserve"
          >
            <g>
              <polygon class="st0" points="78,105 15,105 24,87 87,87 	" />
              <polygon class="st0" points="96,69 33,69 42,51 105,51 	" />
              <polygon class="st0" points="78,33 15,33 24,15 87,15 	" />
            </g>
          </svg>
        </router-link>
      </div>

      <ul class="navbar-nav flex-fill w-100 mb-2">
        <li class="nav-item w-100">
          <router-link class="nav-link" to="/dashboard">
            <i class="fe fe-layers fe-16"></i>
            <span class="ml-3 item-text">Dashboard</span>
            <!-- <span class="badge badge-pill badge-primary">New</span> -->
          </router-link>
        </li>
      </ul>
      <p class="text-muted nav-heading mt-4 mb-1">
        <span>Students</span>
      </p>
      <ul class="navbar-nav flex-fill w-100 mb-2">
        <li class="nav-item w-100">
          <router-link class="nav-link" to="/profile">
            <i class="fe fe-layers fe-16"></i>
            <span class="ml-3 item-text">My Profile</span>
            <!-- <span class="badge badge-pill badge-primary">New</span> -->
          </router-link>
        </li>
        <li class="nav-item dropdown">
          <a
            href="#student"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle nav-link"
          >
            <i class="fe fe-credit-card fe-16"></i>
            <span class="ml-3 item-text">Student </span>
          </a>
          <ul class="collapse list-unstyled pl-4 w-100" id="student">
            <a class="nav-link pl-3" href="/"
              ><span class="ml-1">Progress Report</span></a
            >
            <router-link class="nav-link pl-3" to="/">
              <span class="ml-1">Certificates</span></router-link
            >
          </ul>
        </li>
      </ul>
      <p class="text-muted nav-heading mt-4 mb-1">
        <span>My Courses</span>
      </p>
      <ul class="navbar-nav flex-fill w-100 mb-2">
        <li class="nav-item w-100">
          <router-link class="nav-link" to="/my-courses">
            <i class="fe fe-calendar fe-16"></i>
            <span class="ml-3 item-text">My Courses</span>
          </router-link>
        </li>
        <li class="nav-item w-100">
          <router-link class="nav-link" to="/all-courses">
            <i class="fe fe-calendar fe-16"></i>
            <span class="ml-3 item-text">Available Courses</span>
          </router-link>
        </li>
        <!-- <li class="nav-item dropdown">
          <a
            href="#contact"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle nav-link"
          >
            <i class="fe fe-book fe-16"></i>
            <span class="ml-3 item-text">Course Management</span>
          </a>
          <ul class="collapse list-unstyled pl-4 w-100" id="contact">
            <a class="nav-link pl-3" href="/contacts-list.html"
              ><span class="ml-1">Course List</span></a
            >
            <router-link class="nav-link pl-3" to="/coursedetail">
              <span class="ml-1">Course Detail</span></router-link
            >
          </ul>
        </li> -->
      </ul>
      <!-- live class sections -->
      <!-- start -->
      <p class="text-muted nav-heading mt-4 mb-1">
        <span>Live Session</span>
      </p>
      <ul class="navbar-nav flex-fill w-100 mb-2">
        <li class="nav-item w-100">
          <router-link class="nav-link" to="/liveclass">
            <i class="fe fe-calendar fe-16"></i>
            <span class="ml-3 item-text">Live Session List</span>
          </router-link>
        </li>
      </ul>
      <!--live class section end -->
      <p class="text-muted nav-heading mt-4 mb-1">
        <span>Payments</span>
      </p>
      <ul class="navbar-nav flex-fill w-100 mb-2">
        <li class="nav-item dropdown">
          <a
            href="#pages"
            data-toggle="collapse"
            aria-expanded="false"
            class="dropdown-toggle nav-link"
          >
            <i class="fe fe-file fe-16"></i>
            <span class="ml-3 item-text">My Payments</span>
          </a>
          <ul class="collapse list-unstyled pl-4 w-100 w-100" id="pages">
            <li class="nav-item">
              <router-link class="nav-link pl-3" to="/make-payment">
                <span class="ml-1 item-text">Pay</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link pl-3" to="/my-invoices">
                <span class="ml-1 item-text">My Invoices</span>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
import $ from "jquery";
export default {
  mounted() {
    $(".sidebar-left").hover(
      function () {
        $(".vertical").hasClass("collapsed") &&
          $(".vertical").addClass("hover"),
          $(".narrow").hasClass("open") || $(".vertical").addClass("hover");
      },
      function () {
        $(".vertical").hasClass("collapsed") &&
          $(".vertical").removeClass("hover"),
          $(".narrow").hasClass("open") || $(".vertical").removeClass("hover");
      }
    ),
      $(".toggle-sidebar").on("click", function () {
        $(".navbar-slide").toggleClass("show");
      });
  },
};
</script>

<style></style>
