import axios from "axios";
import Router from "../router/index";
import Store from "../store/index";

const BASE_URL =  process.env.VUE_APP_API_URL;

export { BASE_URL };

const mainApi = axios.create({
	withCredentials: true,
	baseURL: BASE_URL,
	headers: {
		Accept: "application/json",
		ContentType: "application/json",
		"Access-Control-Allow-Origin":  process.env.VUE_APP_APP_URL,
	},
});

mainApi.interceptors.response.use(
	function (response) {
		return Promise.resolve(response);
	},
	function (error) {
		if (
			error.response.status == 401 &&
			Router.currentRoute.path != "/login"
		) {
			Store.dispatch("auth/forceLogout", null, { root: true });
			Router.push("/login");
			return Promise.reject(error);
		}

		return Promise.reject(error);
	}
);

export default mainApi;
