<template>
  <div>
    <h3>Make Payment</h3>
    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h5>Make a payment</h5>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-8">
                <label for="">Select Course: </label>
                <select v-model="newReceipt.enrollment_id" class="form-control">
                  <option disabled :value="null">Select Course</option>
                  <option
                    v-for="course in courses"
                    :key="course.id"
                    :value="course.id"
                    :class="{
                      overdue: course.overdue > 0,
                    }"
                  >
                    {{ course.course.name }} - {{ course.code }} &nbsp;&nbsp;
                    <span v-if="course.overdue > 0">(Overdue)</span>
                  </option>
                </select>
              </div>
            </div>
            <hr class="m-4" v-if="selectedCourse" />

            <div v-if="selectedCourse">
              <div class="row justify-content-center">
                <div class="col-11 my-3">
                  <div class="form-row">
                    <div class="form-group col-4 d-flex">
                      <label class="font-weight-bold mr-2">Course : </label>
                      <p>{{ selectedCourse.course.name }}</p>
                    </div>
                    <div class="form-group col-4 d-flex">
                      <label class="font-weight-bold mr-2"
                        >Course Code :
                      </label>
                      <p>{{ selectedCourse.course.code | uppercase }}</p>
                    </div>
                    <div class="form-group col-4 d-flex">
                      <label class="font-weight-bold mr-2">Batch : </label>
                      <p>{{ selectedCourse.batch.batch_code }}</p>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-4 d-flex">
                      <label class="font-weight-bold mr-2"
                        >Enrolled On :
                      </label>
                      <p>{{ selectedCourse.created_at | myDate }}</p>
                    </div>
                    <div class="form-group col-4 d-flex">
                      <label class="font-weight-bold mr-2"
                        >Access Status :
                      </label>
                      <p
                        :class="{
                          'text-danger font-weight-bold':
                            selectedCourse.status == 'disabled',
                        }"
                      >
                        {{ selectedCourse.status | uppercase }}
                      </p>
                    </div>
                    <div class="form-group col-4 d-flex">
                      <label class="font-weight-bold mr-2"
                        >Batch Status :
                      </label>
                      <p>{{ selectedCourse.batch.status | uppercase }}</p>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group col-4 d-flex">
                      <label class="font-weight-bold mr-2"
                        >Course Total :
                      </label>
                      <p>
                        {{ selectedCourse.payment_schedule_count | currency }}
                      </p>
                    </div>
                    <div class="form-group col-4 d-flex">
                      <label class="font-weight-bold mr-2"
                        >Paid Total Total :
                      </label>
                      <p>{{ selectedCourse.payments_count | currency }}</p>
                    </div>
                    <div class="form-group col-4 d-flex">
                      <label class="font-weight-bold mr-2">Remaining : </label>
                      <p>
                        {{
                          (Number(selectedCourse.payment_schedule_count) -
                            Number(selectedCourse.payments_count))
                            | currency
                        }}
                      </p>
                    </div>
                  </div>

                  <div v-if="selectedCourse.overdue > 0" class="form-row">
                    <div class="form-group col-4 d-flex">
                      <label class="font-weight-bold mr-2 text-danger"
                        >Overdue Amount :
                      </label>
                      <p class="text-danger">
                        {{ selectedCourse.overdue | currency }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="
                  Number(selectedCourse.payment_schedule_count) -
                    Number(selectedCourse.payments_count) >
                  0
                "
                class="row justify-content-center pb-3 mb-5"
              >
                <div class="col-8">
                  <div class="card">
                    <div class="card-body">
                      <div class="form-row">
                        <div class="form-group col-6">
                          <label for="">Amount: </label>
                          <input
                            v-model="newReceipt.amount"
                            type="number"
                            class="form-control"
                            :class="{
                              'is-invalid': newReceipt.errors.has('amount'),
                            }"
                          />
                          <div
                            class="invalid-feedback"
                            v-if="newReceipt.errors.has('amount')"
                            v-html="newReceipt.errors.get('amount')"
                          />
                        </div>
                        <div class="form-group col-6">
                          <label for="">Select Method</label>
                          <select
                            v-model="newReceipt.type"
                            class="form-control"
                            :class="{
                              'is-invalid': newReceipt.errors.has('type'),
                            }"
                          >
                            <option disabled :value="null">
                              Select Payment Method
                            </option>
                            <option value="card">Card Payment</option>
                            <option value="bank">Bank Deposit</option>
                          </select>
                          <div
                            class="invalid-feedback"
                            v-if="newReceipt.errors.has('type')"
                            v-html="newReceipt.errors.get('type')"
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-12">
                          <FileDialog
                            v-if="newReceipt.type == 'bank'"
                            :isInvalid="
                              newReceipt.errors.errors.hasOwnProperty('image')
                            "
                            :accept="''"
                            @output="
                              (file) => {
                                this.newReceipt.image = file[0];
                              }
                            "
                            :label="'Upload your proof slip'"
                          >
                            <p
                              slot="errorMessage"
                              class="invalid-feedback d-block"
                            >
                              Slip Image is required
                            </p>
                          </FileDialog>
                        </div>
                      </div>
                      <div class="form-row mt-3">
                        <div class="col-12">
                          <button
                            :disabled="newReceipt.busy"
                            @click="createAndPay"
                            style="color: white"
                            class="btn btn-block btn-success font-weight-bold"
                          >
                            Pay
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileDialog from "vue-file64";
import mainApi from "../../api/mainApi";
import swal from "sweetalert2";

import Form from "vform";
Form.axios = mainApi;

import _ from "lodash";
export default {
  name: "MakePayment",

  components: {
    FileDialog,
  },

  created() {
    this.getMyCourse();
  },

  mounted() {
    if (this.$route.query.enrollmentId) {
      this.newReceipt.enrollment_id = Number(this.$route.query.enrollmentId);
    }
  },

  data() {
    return {
      courses: null,

      newReceipt: new Form({
        enrollment_id: null,
        amount: "",
        type: null,
        image: null,
      }),
    };
  },

  methods: {
    getMyCourse() {
      mainApi
        .get("/api/lms/student-enrollment-info")
        .then((response) => {
          if (response.status == 200) {
            this.courses = response.data;
          }
        })
        .catch(() => {
          swal.fire({
            title: "Opps...",
            icon: "error",
            text: "Failed to get course information, please refresh!",
          });
        });
    },

    redirectToIpg({ params, url }) {
      const tempForm = document.createElement("form");
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          const hiddenField = document.createElement("input");
          hiddenField.type = "hidden";
          hiddenField.name = key;
          hiddenField.value = params[key];

          tempForm.appendChild(hiddenField);
        }
      }

      tempForm.method = "post";
      tempForm.action = url;
      document.body.appendChild(tempForm);
      tempForm.submit();
    },

    createAndPay() {
      swal
        .fire({
          title: "Are you sure?",
          text: "You are about to make a payment!",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.newReceipt
              .post("api/lms/create-and-pay")
              .then((response) => {
                if (response.status == 201) {
                  this.getMyCourse();
                  swal
                    .fire({
                      title: "Okay",
                      icon: "success",
                      text: response.data.message,
                    })
                    .then(() => {
                      this.$router.push("/my-invoices");
                    });
                }
              })
              .catch((error) => {
                if (error.response.status == 303) {
                  // Redirect to IPG
                  return this.redirectToIpg(error.response.data);
                }

                swal.fire({
                  title: "Opps...",
                  icon: "error",
                  text: error.response.data.message,
                });
              });
          }
        });
    },
  },

  computed: {
    selectedCourse() {
      if (this.newReceipt.enrollment_id) {
        return _.find(this.courses, { id: this.newReceipt.enrollment_id });
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.overdue {
  background-color: rgba(255, 0, 0, 0.526);
  color: white;
}
</style>
