<template>
  <div class="wrapper vh-100">
    <div class="row align-items-center h-100 mx-0">
      <div class="col-lg-3 col-md-4 col-10 mx-auto text-center">
        <a class="navbar-brand mx-auto mt-2 flex-fill text-center">
          <svg
            version="1.1"
            id="logo"
            class="navbar-brand-img brand-md"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 120 120"
            xml:space="preserve"
          >
            <g>
              <polygon
                class="st0"
                points="78,105 15,105 24,87 87,87 	"
              ></polygon>
              <polygon class="st0" points="96,69 33,69 42,51 105,51 	"></polygon>
              <polygon class="st0" points="78,33 15,33 24,15 87,15 	"></polygon>
            </g>
          </svg>
        </a>
        <h1 v-if="isLoading" class="h3 my-5">Redirecting...</h1>

        <div
          v-if="isLoading"
          class="row d-flex my-5 p-5 justify-content-center"
        >
          <breeding-rhombus-spinner
            :animation-duration="2000"
            :size="50"
            color="#1b68ff"
          />
        </div>

        <h2 v-if="status == 'SUCCESS'" class="text-success">
          Payment Successful
        </h2>
        <h2 v-if="status == 'FAILED'" class="text-danger">Payment Failed</h2>

        <p v-if="isLoading" class="mb-5 mt-3">
          Please be patient, while we redirect you, Thanks.
        </p>

        <p class="mt-2 mb-3 text-muted">{{ company }} © 2020</p>
      </div>
    </div>
  </div>
</template>

<script>
import { BreedingRhombusSpinner } from "epic-spinners";
export default {
  name: "IPGRedirect",

  mounted() {
    let status = this.$route.query.status;
    this.status = status;

    this.gotoInvoices();
  },

  components: {
    BreedingRhombusSpinner,
  },

  data() {
    return {
      isLoading: true,
      status: "",
    };
  },

  methods: {
    gotoInvoices() {
      setTimeout(() => {
        this.$router.replace("/my-invoices");
      }, 5000);
    },
  },

  computed: {
    company() {
      return process.env.VUE_APP_NAME;
    },
  },
};
</script>

<style></style>
