import mainApi from "../api/mainApi";

const state = {
  courseModules: [],
};

const mutations = {
  setCourseModules(state, payload) {
    state.courseModules = payload;
  },
};

const actions = {
  getModulesByCourseID({ commit }, { courseId }) {
    return new Promise((resolve, reject) => {
      mainApi
        .get("/api/lms/getModules/" + courseId)
        .then((response) => {
          if (response.status == 200) {
            commit("setCourseModules", response.data);
            resolve();
          }
        })
        .catch((error) => {
          reject(error.response.data.message);
        });
    });
  },
};

const getters = {
  getCourseModules(state) {
    return state.courseModules;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
