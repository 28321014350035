import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ErrorNotFound from "../views/ErrorNotFound.vue";
import Profile from "../views/page/Profile.vue";
import MyCourse from "../views/page/MyCourse.vue";
import Login from "../Login.vue";
import AdminLayout from "../views/layouts/AdminLayout.vue";
import LiveClass from "../views/page/LiveClass.vue";
import CourseModule from "../views/page/CourseModule.vue";
import CourseMaterial from "../views/page/CourseMaterials.vue";
import VerifyNotice from "../views/page/VerifyNotice.vue";
import VerifyView from "../views/page/VerifyingView.vue";
import CourseList from "../views/page/CourseList.vue";
import MakePayment from "../views/page/MakePayment.vue";
import MyInvoices from "../views/page/MyInvoices.vue";
import IPGRedirection from "../views/page/IPGRedirect.vue";

import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Login" },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.isAuthenticated == true &&
        typeof store.state.auth.user === "object"
      ) {
        next("/dashboard");
      } else next();
    },
  },
  {
    path: "/dashboard",
    component: AdminLayout,
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
      },
      {
        path: "/my-courses",
        name: "MyCourse",
        component: MyCourse,
      },
      {
        path: "/course/:id",
        name: "CourseModule",
        props: true,
        component: CourseModule,
      },
      {
        path: "/course/:id/module/:moduleId",
        name: "CourseMaterial",
        props: true,
        component: CourseMaterial,
      },
      {
        path: "/liveclass",
        name: "LiveClass",
        component: LiveClass,
      },
      {
        path: "/all-courses",
        name: "CourseList",
        component: CourseList,
      },
      {
        path: "/make-payment",
        name: "MakePayment",
        component: MakePayment,
      },
      {
        path: "/my-invoices",
        name: "MyInvoices",
        component: MyInvoices,
      },
    ],
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.isAuthenticated == false &&
        store.state.auth.user == null
      ) {
        return next("/login");
      }

      if (
        store.state.auth.isAuthenticated == true &&
        typeof store.state.auth.user === "object" &&
        store.state.auth.emailVerified == null
      ) {
        return next("/verification-notice");
      }

      next();
    },
  },

  {
    path: "/ipg-redirect",
    name: "IPGRedirect",
    component: IPGRedirection,
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.isAuthenticated == false &&
        store.state.auth.user == null
      ) {
        return next("/404");
      }

      next();
    },
  },

  {
    path: "/verification-notice",
    name: "VerificationNotice",
    component: VerifyNotice,
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.isAuthenticated == false &&
        store.state.auth.user == null
      ) {
        return next("/login");
      }

      if (
        (store.state.auth.isAuthenticated == true &&
          typeof store.state.auth.user === "object",
        store.state.auth.emailVerified != null)
      ) {
        return next("/dashboard");
      }

      next();
    },
  },

  {
    path: "/email-verify/:id/:hash",
    name: "VerificationView",
    props: true,
    component: VerifyView,
    beforeEnter: (to, from, next) => {
      if (
        (store.state.auth.isAuthenticated == true &&
          typeof store.state.auth.user === "object",
        store.state.auth.emailVerified != null)
      ) {
        return next("/dashboard");
      }

      next();
    },
  },

  {
    path: "*",
    name: "ErrorNotFound",
    component: ErrorNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
