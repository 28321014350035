<template>
  <div class="vertical light">
    <div class="wrapper">
      <Navbar />
      <Sidebar />
      <main role="main" class="main-content">
        <div id="app"><router-view /></div>
        <Notification />
        <Shortcut />
      </main>
      <!-- main -->
    </div>
    <!-- .wrapper -->
  </div>
</template>

<script>
import Navbar from "./inc/Navbar.vue";
import Sidebar from "./inc/Sidebar.vue";
import Notification from "./inc/Notification.vue";
import Shortcut from "./inc/Shortcut.vue";
export default {
  name: "AdminLayout",
  components: {
    Navbar,
    Sidebar,
    Notification,
    Shortcut,
  },
};
</script>
