<template>
  <div>
    <div class="row mb-2">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h3>My Course List</h3>
      </div>
    </div>

    <div class="row">
      <div
        class="col-sm-4 col-md-4 col-lg-4"
        v-for="(course, index) in CourseData"
        :key="index"
      >
        <div
          class="card mt-2"
          :class="{
            'overdue-card': course.overdue > 0,
          }"
        >
          <img
            :src="getThumbFromArray(course.course.images)"
            alt=""
            class="card-img-top"
          />
          <div class="card-body">
            <h5 class="card-title">{{ course.course.name }}</h5>
            <div class="row mb-1">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <b>Duration :</b>
                {{ course.course.duration_amount }}&nbsp;
                <span v-if="course.course.duration_amount > 1">
                  <span v-if="course.course.duration_type == 'd'">Days</span>
                  <span v-if="course.course.duration_type == 'w'">Weeks</span>
                  <span v-if="course.course.duration_type == 'm'">Months</span>
                  <span v-if="course.course.duration_type == 'y'">Years</span>
                </span>
                <span v-else>
                  <span v-if="course.course.duration_type == 'd'">Day</span>
                  <span v-if="course.course.duration_type == 'w'">Week</span>
                  <span v-if="course.course.duration_type == 'm'">Month</span>
                  <span v-if="course.course.duration_type == 'y'">Year</span>
                </span>
              </div>
            </div>
            <!-- <div class="row mb-1">
              <div class="col-12"><b>Lectures :</b> ----</div>
            </div> -->
            <div class="row mb-2">
              <div class="col-sm-12 col-md-12 col-lg-12 d-flex">
                <b class="pr-1">Status : </b>
                <div v-if="course.status == 'pending'">
                  <span class="badge badge-danger" style="font-size: 14px"
                    >Pending</span
                  >
                </div>
                <div v-if="course.status == 'active'">
                  <span class="badge badge-success" style="font-size: 14px"
                    >Active</span
                  >
                </div>
                <div v-if="course.status == 'disabled'">
                  <span class="badge badge-danger" style="font-size: 14px"
                    >Disabled</span
                  >
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <b>Course Description:</b>
              </div>
            </div>
            <p class="card-text">
              {{ course.course.short_description }}
            </p>

            <div v-if="course.status == 'pending'" class="row mb-1">
              <div class="col-sm-8 col-md-8 col-lg-8">
                <h4>{{ course.payment_schedule_count | currency }}</h4>
              </div>
            </div>

            <div
              class="row mb-1"
              v-else-if="course.status == 'disabled' && course.overdue > 0"
            >
              <div class="col-sm-12 col-md-12 col-lg-12">
                <h4 class="text-danger">
                  {{ course.overdue | currency }}
                  <small class="font-weight-bold">Overdue</small>
                </h4>
              </div>
            </div>

            <div class="row mb-1" v-else>
              <div class="col-sm-12 col-md-12 col-lg-12">
                <h4>
                  {{ course.code }}
                  <small class="font-weight-bold">Batch</small>
                </h4>
              </div>
            </div>

            <!-- <button type="button" class="btn mb-2 btn-primary btn-lg">
              Ugrade
            </button> -->
            <div v-if="course.status == 'pending'">
              <button
                @click="gotoPayment(course.id)"
                class="btn mb-2 btn-primary btn-lg col-sm-6 col-md-6 col-lg-6"
              >
                Pay Now
              </button>
            </div>
            <div v-if="course.status == 'disabled' && course.overdue > 0">
              <button
                @click="gotoPayment(course.id)"
                class="btn mb-2 btn-danger btn-lg col-sm-6 col-md-6 col-lg-6"
              >
                Pay Overdue
              </button>
            </div>
            <div v-if="course.status == 'active'">
              <router-link
                :to="'/course/' + course.course.id"
                class="btn mb-2 btn-primary btn-lg col-sm-6 col-md-6 col-lg-6"
                >View Course</router-link
              >
            </div>
            <!-- <a href="" class="btn btn-outline-success btn-sm">Read More</a> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Payment pay model start -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="pay_modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modelTitleId"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Payment</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-12">
              <div class="row mt-2">
                <select>
                  <option value="Card">Card Payment</option>
                  <option value="Bank">Bank Deposit</option>
                </select>
              </div>
              <div class="row mt-2"></div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary">Pay</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainApi from "../../api/mainApi";
import { mapGetters } from "vuex";
import { find } from "lodash";
// import $ from "jquery";
export default {
  name: "MyCourse",

  props: {
    // course: { type: Object, required: true },
  },

  created() {
    this.getCoursesByStudent();
  },

  data() {
    return {
      CourseData: {},
    };
  },

  mounted() {},

  methods: {
    gotoPayment(enrollmentId) {
      this.$router.push({
        path: "/make-payment",
        query: {
          enrollmentId,
        },
      });
    },
    getCoursesByStudent() {
      mainApi
        .get("/api/lms/getCourses")
        .then((response) => {
          if (response.status == 200) {
            this.CourseData = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getThumbFromArray(images) {
      return find(images, { dimension: "540X360" }).path;
    },
  },

  computed: {
    ...mapGetters("auth", { user: "getUser" }),
  },
};
</script>

<style lang="scss" scoped>
.overdue-card {
  border: 3px solid rgba(255, 0, 0, 0.526);
  background-color: rgba(255, 0, 0, 0.526);

  & .card-body {
    background-color: #ffe7e7;
  }
}
</style>
>
