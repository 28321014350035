<template>
  <div
    class="modal fade modal-shortcut modal-slide"
    tabindex="-1"
    role="dialog"
    aria-labelledby="defaultModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="defaultModalLabel">Shortcuts</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body px-5">
          <div class="row align-items-center">
            <div class="col-6 text-center">
              <router-link class="text-decoration-none" to="/dashboard">
                <div class="squircle bg-success justify-content-center">
                  <i
                    class="fe fe-layers fe-32 align-self-center text-white"
                  ></i>
                </div>
              </router-link>
              <p>Dashboard</p>
            </div>
            <div class="col-6 text-center">
              <router-link class="text-decoration-none" to="/my-courses">
                <div class="squircle bg-primary justify-content-center">
                  <i class="fe fe-info fe-32 align-self-center text-white"></i>
                </div>
              </router-link>
              <p>My Courses</p>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-6 text-center">
              <router-link class="text-decoration-none" to="/liveclass">
                <div class="squircle bg-primary justify-content-center">
                  <i
                    class="fe fe-activity fe-32 align-self-center text-white"
                  ></i>
                </div>
              </router-link>
              <p>Live Sessions</p>
            </div>
            <div class="col-6 text-center">
              <router-link class="text-decoration-none" to="/make-payment">

              <div class="squircle bg-primary justify-content-center">
                <i
                  class="fe fe-dollar-sign fe-32 align-self-center text-white"
                ></i>
              </div>
              </router-link>
              <p>Payments</p>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-6 text-center">
              <router-link class="text-decoration-none" to="/profile">
                <div class="squircle bg-primary justify-content-center">
                  <i
                    class="fe fe-settings fe-32 align-self-center text-white"
                  ></i>
                </div>
              </router-link>
              <p>Settings</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
