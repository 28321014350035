<template>
  <div class="wrapper vh-100">
    <div class="row align-items-center h-100 mx-0">
      <div class="col-lg-3 col-md-4 col-10 mx-auto text-center">
        <a class="navbar-brand mx-auto mt-2 flex-fill text-center">
          <svg
            version="1.1"
            id="logo"
            class="navbar-brand-img brand-md"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 120 120"
            xml:space="preserve"
          >
            <g>
              <polygon
                class="st0"
                points="78,105 15,105 24,87 87,87 	"
              ></polygon>
              <polygon class="st0" points="96,69 33,69 42,51 105,51 	"></polygon>
              <polygon class="st0" points="78,33 15,33 24,15 87,15 	"></polygon>
            </g>
          </svg>
        </a>
        <h1 v-if="isLoading" class="h3 my-5">Verifying please wait...</h1>

        <div
          v-if="isLoading"
          class="row d-flex my-5 p-5 justify-content-center"
        >
          <breeding-rhombus-spinner
            :animation-duration="2000"
            :size="50"
            color="#1b68ff"
          />
        </div>

        <p v-if="isLoading" class="mb-5">
          Please be patient, this may take some time. Thanks.
        </p>

        <div class="my-5" v-if="error">
          <h6 class="text-danger mt-4">
            {{ error }}
          </h6>
        </div>
        <div class="my-5" v-if="successMsg">
          <h6 class="text-success mt-4">
            {{ successMsg }}
          </h6>
        </div>

        <p class="mt-2 mb-3 text-muted">{{ company }} © 2020</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import mainApi from "../../api/mainApi";
import { BreedingRhombusSpinner } from "epic-spinners";
export default {
  name: "VerifyView",

  props: {
    id: {
      type: String,
      required: true,
    },
    hash: {
      type: String,
      required: true,
    },
  },

  components: {
    BreedingRhombusSpinner,
  },

  data() {
    return {
      isLoading: true,
      error: "",
      successMsg: "",
    };
  },

  mounted() {
    this.sendVerificationHash();
  },

  methods: {
    ...mapActions("auth", ["logout"]),
    sendVerificationHash() {
      mainApi
        .post(`/api/lms-email/verify/${this.id}/${this.hash}`)
        .then((response) => {
          setTimeout(() => {
            this.isLoading = false;
            this.successMsg = response.data.message;
            this.sendToLogin();
          }, 5000);
        })
        .catch((error) => {
          setTimeout(() => {
            this.isLoading = false;
            this.error = error.response.data.message;
            this.sendToLogin();
          }, 5000);
        });
    },

    sendToLogin() {
      this.logout();
      setTimeout(() => {
        this.$router.replace("/login");
      }, 5000);
    },
  },

  computed: {
    company() {
      return process.env.VUE_APP_NAME;
    },
  },
};
</script>

<style></style>
