import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

var ls = new SecureLS({ isCompression: false });

import auth from "./store-auth";
import courseModules from "./store-course-modules";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    courseModules,
  },
  plugins: [
    createPersistedState({
      key: window.location.host,
      paths: ["auth"],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
