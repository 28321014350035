<template>
  <div>
    <!-- <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h3>Today Classes</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">All Sessions</h4>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Course ID</th>
                    <th>Session Name</th>
                    <th>Lecture Name</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CS-1201</td>
                    <td>Introduction Computing</td>
                    <td>Mr.Janith Kumara</td>
                    <td>12:30 PM</td>
                    <td>14:30 PM</td>
                    <td><label class="badge badge-danger">Pending</label></td>
                    <td>
                      <button type="button" class="btn btn-primary btn-sm">
                        Join
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>CS-1201</td>
                    <td>Introduction Computing</td>
                    <td>Mr.Janith Kumara</td>
                    <td>12:30 PM</td>
                    <td>14:30 PM</td>
                    <td>
                      <label class="badge badge-success">Compelted</label>
                    </td>
                    <td>
                      <button type="button" class="btn btn-primary btn-sm">
                        Join
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- All class sessions -->
    <div class="row mt-3">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h3>All Classes</h3>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-12 col-md-12 col-sm-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">All Sessions</h4>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Course Name</th>
                    <th>Session Name</th>
                    <th>Lecture Name</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(session, index) in liveSession.data" :key="index">
                    <td>{{ session.batch.course.name | nameStandard }}</td>
                    <td>
                      {{ session.zoom_response.topic }}
                    </td>
                    <td>
                      <span v-if="session.user.employee">{{
                        session.user.employee.name | nameStandard
                      }}</span>
                      <span v-else>Unknown</span>
                    </td>
                    <td>{{ session.start_time | myDate }}</td>
                    <td>{{ session.start_time | myTime }}</td>
                    <td style="text-transform: capitalize">
                      {{ session.zoom_response.status }}
                    </td>
                    <td>
                      <button
                        @click="joinMeetingUrl(session.join_url)"
                        type="button"
                        class="btn btn-primary btn-sm"
                      >
                        Join
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-if="liveSession.next_page_url"
              class="row d-flex justify-content-center mt-2"
            >
              <div class="col-6">
                <button
                  @click="loadMoreData(liveSession.next_page_url)"
                  class="btn btn-block btn-sm btn-outline-info"
                >
                  Load More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainApi from "../../api/mainApi";
import swal from "sweetalert2";

import { mapGetters } from "vuex";
export default {
  name: "LiveClass",

  created() {
    this.getZoomMeetings();
    // this.getStudentLiveSessionsToday();
  },

  data() {
    return {
      liveSession: {},
      studentData: {},

      limit: 20,
    };
  },
  methods: {
    getZoomMeetings(page = 1) {
      mainApi
        .get("/api/lms/getStudentLiveSessions", {
          params: {
            limit: this.limit,
            page,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.liveSession = response.data;
          }
        })
        .catch((error) => {
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        });
    },
    joinMeetingUrl(link) {
      window.open(link);
    },

    loadMoreData(url) {
      mainApi
        .get(url, {
          params: {
            limit: this.limit,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.liveSession.data = [
              ...this.liveSession.data,
              ...response.data.data,
            ];
            this.liveSession.next_page_url = response.data.next_page_url;
          }
        })
        .catch((error) => {
          swal.fire({
            title: "Opps...",
            icon: "error",
            text: error.response.data.message,
          });
        });
    },
  },
  computed: {
    ...mapGetters("auth", { user: "getUser" }),
  },
};
</script>

<style></style>
