// import axios from "axios";
import mainApi from "../api/mainApi";

const state = {
  isAuthenticated: false,
  user: null,
  emailVerified: false,
};

const mutations = {
  setIsAuthenticated(state, payload) {
    state.isAuthenticated = payload;
  },
  setUser(state, payload) {
    state.user = payload;
  },
  setEmailVerified(state, payload) {
    state.emailVerified = payload;
  },
};

const actions = {
  async getCsrfCookie() {
    mainApi.get("sanctum/csrf-cookie");
  },

  async login({ commit }, { email, password }) {
    return new Promise((resolve, reject) => {
      mainApi
        .post("api/lms/student/login", { email, password })
        .then((response) => {
          if (response.status == 201) {
            commit("setIsAuthenticated", true);
            commit("setUser", response.data);
            commit("setEmailVerified", response.data.email_verified_at);
            resolve();
          }
        })
        .catch((error) => {
          reject(error.response.data.message);
        });
    });
  },

  forceLogout({ commit }) {
    commit("setIsAuthenticated", false);
    commit("setUser", null);
    commit("setEmailVerified", null);
  },

  async logout({ commit }) {
    return new Promise((resolve, reject) => {
      mainApi
        .post("api/lms/student/logout")
        .then((response) => {
          if (response.status == 200) {
            commit("setIsAuthenticated", false);
            commit("setUser", null);
            commit("setEmailVerified", null);
            resolve();
          }
        })
        .catch((error) => {
          reject(error.response.data.message);
        });
    });
  },

  async resendVerificationEmail({ state }) {
    const userId = state.user.id;

    return new Promise((resolve, reject) => {
      mainApi
        .post(`/api/lms-email/verification-notification/${userId}`)
        .then((response) => {
          if (response.status == 200) {
            resolve();
          }
        })
        .catch((error) => {
          reject(error.response.data.message);
        });
    });
  },

  async refreshUserDetail({ commit }) {
    return new Promise((resolve, reject) => {
      mainApi
        .get("api/lms/profile/me")
        .then((response) => {
          if (response.status == 200) {
            commit("setUser", response.data);
			resolve();
          }
        })
        .catch((error) => {
          reject(error.response.data.message);
        });
    });
  },
};

const getters = {
  getUser(state) {
    return state.user;
  },
  getIsAuthenticated(state) {
    return state.isAuthenticated;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
