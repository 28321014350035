<template>
  <div v-if="user" class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="card">
          <img class="card-img-top" src="holder.js/100x180/" alt="" />
          <div class="card-body">
            <div class="row justify-content-center">
              <a href="#!" class="avatar avatar-xl">
                <img
                  v-if="user.image"
                  :src="user.image.path"
                  class="avatar-img rounded-circle"
                />
                 <img
                  v-else
                  src="/assets/avatars/default_user.svg"
                  class="avatar-img rounded-circle"
                />
                
              </a>
            </div>
            <div class="row mt-3 justify-content-center">
              <h4 class="mb-1">{{ user.full_name }}</h4>
            </div>
            <div class="row justify-content-center">
              <p class="small">
                <span class="badge badge-dark p-1">{{
                  user.primary_address
                }}</span>
              </p>
            </div>
            <div class="row justify-content-center">
              <span class="small text-muted mb-0"
                ><a href="#" @click="showEditProfile(user)"
                  >Edit Profile</a
                ></span
              >
            </div>

            <div class="row justify-content-center">
              <span class="small text-muted mb-0"
                >Joined {{ user.created_at | myDate }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="card shadow">
          <div class="card-header">
            <strong class="card-title">My Course List</strong>
            <a class="float-right small text-muted" href="#!">View all</a>
          </div>
          <div class="card-body">
            <div class="list-group list-group-flush my-n3">
              <div class="list-group-item">
                <div
                  v-for="(data, index) in CourseData"
                  :key="index"
                  class="row align-items-center my-3"
                >
                  <div class="col-sm-2 col-md-2 col-lg-2">
                    <div class="circle circle-md bg-secondary">
                      <span class="fe fe-folder fe-16 text-white"></span>
                    </div>
                  </div>
                  <div class="col-sm-5 col-md-9 col-lg-9 mt-2">
                    <strong
                      >{{ data.course.name }} -
                      {{ data.batch.batch_code }}</strong
                    >
                    <div class="my-0 text-muted small mt-1">
                      {{ data.course.short_description }}
                    </div>
                  </div>
                  <!-- <div class="col-auto">
                    <strong>85% completed</strong>
                    <div class="progress mt-2" style="height: 4px">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 85%"
                        aria-valuenow="85"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div> -->
                  <div class="col-auto">
                    <router-link to="">
                      <button class="btn btn-primary">view</button></router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- / .card-body -->
        </div>
      </div>
    </div>

    <!-- start show edite profile modal -->
    <!-- Modal -->
    <div
      class="modal fade"
      ref="showModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modelTitleId"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Profile</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-8">
                <FileDialog
                  :key="refreshCounter"
                  :isInvalid="
                    profile.errors.errors.hasOwnProperty('image.file')
                  "
                  :accept="''"
                  @output="
                    (file) => {
                      this.profile.image = file[0];
                    }
                  "
                  :label="'Upload your profile image'"
                >
                  <p
                    slot="errorMessage"
                    v-html="profile.errors.get('image.file')"
                    class="invalid-feedback d-block"
                  ></p>
                </FileDialog>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6">
                <label for="">User Name:</label>
                <input
                  v-model="profile.username"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': profile.errors.has('username'),
                  }"
                />
                <div
                  class="invalid-feedback"
                  v-if="profile.errors.has('username')"
                  v-html="profile.errors.get('username')"
                />
              </div>
            </div>

            <hr class="my-5" />

            <div class="row mt-3">
              <div class="col-6">
                <div class="form-row">
                  <div class="form-group col-12">
                    <label for="">Current Password:</label>
                    <input
                      v-model="profile.current_password"
                      type="password"
                      class="form-control"
                      :class="{
                        'is-invalid': profile.errors.has('current_password'),
                      }"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="profile.errors.has('current_password')"
                      v-html="profile.errors.get('current_password')"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-12">
                    <label for="">Password:</label>
                    <input
                      v-model="profile.password"
                      type="password"
                      class="form-control"
                      :class="{
                        'is-invalid': profile.errors.has('password'),
                      }"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="profile.errors.has('password')"
                      v-html="profile.errors.get('password')"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-12">
                    <label for="">Confirm Password:</label>
                    <input
                      v-model="profile.password_confirmation"
                      type="password"
                      class="form-control"
                      :class="{
                        'is-invalid': profile.errors.has('password'),
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-6">
                <p class="mb-2">Password requirements</p>
                <p class="small text-muted mb-2">
                  To create a new password, you have to meet all of the
                  following requirements:
                </p>
                <ul class="small text-muted pl-4 mb-0">
                  <li>Minimum 8 character</li>
                  <li>At least one special character</li>
                  <li>At least one number</li>
                  <li>Can’t be the same as a previous password</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              @click="updateProfile"
              type="button"
              class="btn btn-primary"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end show edite profile modal -->
  </div>
</template>

<script>
import mainApi from "../../api/mainApi";
import { mapGetters, mapActions } from "vuex";
import $ from "jquery";
import FileDialog from "vue-file64";
import Form from "vform";
Form.axios = mainApi;

import swal from "sweetalert2";

export default {
  name: "Profile",

  data() {
    return {
      refreshCounter: 1,

      CourseData: {},

      profile: new Form({
        image: null,
        username: "",
        password: "",
        password_confirmation: "",
        current_password: "",
      }),
    };
  },
  components: {
    FileDialog,
  },

  mounted() {
    this.profile.username = this.user.username;
  },

  created() {
    this.getUserCourses();
  },
  methods: {
    ...mapActions("auth", {
      refreshUser: "refreshUserDetail",
    }),

    getUserCourses() {
      mainApi
        .get("/api/lms/getCourses")
        .then((response) => {
          if (response.status == 200) {
            this.CourseData = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showEditProfile() {
      $(this.$refs.showModal).modal("show");
    },

    updateProfile() {
      swal
        .fire({
          title: "Are you sure?",
          text: "You are about to update!",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, update!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.profile
              .patch("/api/lms/update-profile")
              .then((response) => {
                if (response.status == 200) {
                  this.refreshUser();
                  $(this.$refs.showModal).modal("hide");
                  this.refreshCounter++;

                  swal.fire({
                    title: "Okay...",
                    icon: "success",
                    text: response.data.message,
                  });
                }
              })
              .catch((error) => {
                swal.fire({
                  title: "Opps...",
                  icon: "error",
                  text: error.response.data.message,
                });
              });
          }
        });
    },
  },
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },
};
</script>

<style></style>
