<template>
  <div class="wrapper vh-100">
    <div class="row align-items-center h-100 mx-0">
      <form
        @submit.prevent="submitLogin()"
        class="col-lg-3 col-md-4 col-10 mx-auto text-center"
      >
        <a class="navbar-brand mx-auto mt-2 flex-fill text-center">
          <svg
            version="1.1"
            id="logo"
            class="navbar-brand-img brand-md"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 120 120"
            xml:space="preserve"
          >
            <g>
              <polygon
                class="st0"
                points="78,105 15,105 24,87 87,87 	"
              ></polygon>
              <polygon class="st0" points="96,69 33,69 42,51 105,51 	"></polygon>
              <polygon class="st0" points="78,33 15,33 24,15 87,15 	"></polygon>
            </g>
          </svg>
        </a>
        <h1 class="h3 my-5">Verify your email</h1>

        <p class="mb-5">
          Click the below button to resend the verification email.
        </p>

        <button
          @click="sendVerification"
          class="btn btn-lg btn-primary btn-block"
          type="submit"
        >
          Resend Email
        </button>
        <div class="mt-5" v-if="error">
          <h6 class="text-danger mt-4">
            {{ error }}
          </h6>
        </div>
        <div class="mt-5" v-if="successMsg">
          <h6 class="text-success mt-4">
            {{ successMsg }}
          </h6>
        </div>

        <div class="my-5">
          <p>
            <a href="#" @click="submitLogout" class="mt-5 mb-3">Click here</a>
            to logout
          </p>
        </div>

        <p class="mt-2 mb-3 text-muted">{{ company }} © 2020</p>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "VerifyNotice",

  data() {
    return {
      error: "",
      successMsg: "",
    };
  },

  methods: {
    ...mapActions("auth", ["resendVerificationEmail", "logout"]),

    sendVerification() {
      this.error = "";
      this.successMsg = "";

      this.resendVerificationEmail()
        .then(() => {
          this.successMsg = "Please check your email, verification email sent!";
        })
        .catch((error) => {
          this.error = error;
        });
    },

    submitLogout() {
      this.logout()
        .then(() => {
          this.$router.replace("/login");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  computed: {
    company() {
      return process.env.VUE_APP_NAME;
    },
  },
};
</script>

<style></style>
