<template>
  <div>
    <div class="mb-4">
      <h4>Course Overview</h4>
    </div>

    <div class="row">
      <div
        class="col-sm-3 col-md-3 col-lg-3"
        v-for="(material, index) in materials"
        :key="index"
      >
        <div class="card shadow text-center mb-4">
          <div class="card-body file">
            <div class="file-action"></div>
            <div v-if="material.type == 'link'">
              <div class="circle circle-lg bg-light my-4">
                <span class="fe fe-link fe-24"></span>
              </div>
            </div>
            <div v-if="material.type == 'note'">
              <div class="circle circle-lg bg-light my-4">
                <span class="fe fe-file-text fe-24"></span>
              </div>
            </div>
            <div v-if="material.type == 'audio'">
              <div class="circle circle-lg bg-light my-4">
                <span class="fe fe-music fe-24"></span>
              </div>
            </div>
            <div v-if="material.type == 'video'">
              <div class="circle circle-lg bg-light my-4">
                <span class="fe fe-film fe-24"></span>
              </div>
            </div>
            <div v-if="material.type == 'image'">
              <div class="circle circle-lg bg-light my-4">
                <span class="fe fe-image fe-24"></span>
              </div>
            </div>
          </div>
          <!-- .card-body -->
          <div class="card-footer bg-transparent border-0 fname">
            <strong>{{ material.title }}</strong
            ><br /><br />
            <button
              type="button"
              class="btn mb-2 btn-secondary"
              @click="downloadMaterial(material.downloadable_url)"
            >
              <span class="fe fe-download fe-16"><span></span></span> Download
            </button>
          </div>
          <!-- .card-footer -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import { find } from "lodash";
export default {
  name: "CourseDetail",
  created() {},
  data() {
    return {};
  },

  props: {
    id: { type: String, required: true },
    moduleId: { type: String, required: true },
  },

  methods: {
    downloadMaterial(link) {
      window.open(link);
    },
  },

  computed: {
    ...mapGetters("courseModules", { modules: "getCourseModules" }),

    thisModule() {
      if (this.modules.length > 0) {
        return this.modules.find((value) => value.id == this.moduleId);
      }
      return null;
    },
    materials() {
      if (this.thisModule) {
        return this.thisModule.materials;
      }
      return [];
    },
  },
};
</script>

<style></style>
