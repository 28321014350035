<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h4>Course Modules</h4>
      </div>
    </div>
    <div class="row mt-3">
      <div
        class="col-sm-4 col-md-4 col-lg-4"
        v-for="(module, index) in modules"
        :key="index"
      >
        <router-link :to="`/course/${id}/module/${module.id}`">
          <div class="card shadow mb-4 selected shadow-lg">
            <div class="card-body file-list">
              <div class="d-flex align-items-center">
                <div class="circle circle-md bg-secondary">
                  <span class="fe fe-folder fe-16 text-white"></span>
                </div>
                <div class="flex-fill ml-4 fname">
                  <strong>{{ module.name }}</strong
                  ><br />
                  <span class="badge badge-light text-muted"
                    >{{ module.materials.length }} files</span
                  >
                  <i class="fe fe-file fe-12 ml-2 text-muted"></i>
                </div>
              </div>
            </div>
            <!-- .card-body -->
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CourseModule",

  props: {
    id: { type: String, required: true },
  },

  created() {
    this.getEssentialData();
  },

  data() {
    return {};
  },
  methods: {
    ...mapActions("courseModules", ["getModulesByCourseID"]),

    getEssentialData() {
      this.getModulesByCourseID({ courseId: this.id });
    },
  },
  computed: {
    ...mapGetters("courseModules", { modules: "getCourseModules" }),
  },
};
</script>

<style></style>
