<template>
  <div class="wrapper vh-100">
    <div class="row align-items-center h-100 mx-0">
      <form
        @submit.prevent="submitLogin()"
        class="col-lg-3 col-md-4 col-10 mx-auto text-center"
      >
        <a class="navbar-brand mx-auto mt-2 flex-fill text-center">
          <svg
            version="1.1"
            id="logo"
            class="navbar-brand-img brand-md"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 120 120"
            xml:space="preserve"
          >
            <g>
              <polygon
                class="st0"
                points="78,105 15,105 24,87 87,87 	"
              ></polygon>
              <polygon class="st0" points="96,69 33,69 42,51 105,51 	"></polygon>
              <polygon class="st0" points="78,33 15,33 24,15 87,15 	"></polygon>
            </g>
          </svg>
        </a>
        <h1 class="h6 mb-3">Sign in</h1>
        <div class="form-group">
          <label for="inputEmail" class="sr-only">Email address</label>
          <input
            type="text"
            class="form-control form-control-lg"
            placeholder="Email address"
            autofocus=""
            v-model="credential.email"
          />
        </div>
        <div class="form-group">
          <label for="inputPassword" class="sr-only">Password</label>
          <input
            type="password"
            class="form-control form-control-lg"
            placeholder="Password"
            required=""
            v-model="credential.password"
          />
        </div>

        <button class="btn btn-lg btn-primary btn-block" type="submit">
          Login
        </button>
        <span class="mt-3" v-if="error"
          ><h5 class="text-danger mt-4">
            Login failed..! Please try again !
          </h5></span
        >

        <p class="mt-5 mb-3 text-muted">{{ company }} © 2020</p>
      </form>
      <!-- <button class="btn btn-lg btn-primary btn-block" @click="logout">
        Logout
      </button>
      <button class="btn btn-lg btn-primary btn-block" @click="checkAccess">
        Check Access
      </button> -->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Login",

  data() {
    return {
      credential: {
        email: "",
        password: "",
      },

      error: "",
    };
  },

  mounted() {
    this.getCsrfCookie();
  },

  methods: {
    ...mapActions("auth", ["login", "getCsrfCookie"]),

    submitLogin() {
      this.login(this.credential)
        .then(() => {
          this.$router.replace("/dashboard");
        })
        .catch((error) => {
          // console.log(error);
          this.error = error;
        });
    },
  },

  computed: {
    company() {
      return process.env.VUE_APP_NAME;
    },
  },
};
</script>

<style></style>
