<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <h3 class="page-title">Dashboard</h3>
        <div class="row mt-3">
          <!-- -------------------------------- -->
          <div class="col-sm-4 col-md-4 col-lg-4 mt-2">
            <div class="card shadow">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col">
                    <span class="h2 mb-0">{{ totalCourseCount }}</span>
                    <h5 class="text-muted mb-2 mt-2">Total Enrollment</h5>
                  </div>
                  <div class="col-auto">
                    <span class="fe fe-32 fe-book-open text-muted mb-0"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ----------------------------- -->
          <div class="col-sm-4 col-md-4 col-lg-4 mt-2">
            <div class="card shadow">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col">
                    <span class="h2 mb-0">{{ liveSessionCount }}</span>
                    <h5 class="text-muted mb-2 mt-2">
                      Total Live Sessions Today
                    </h5>
                  </div>
                  <div class="col-auto">
                    <span
                      class="fe fe-cast fe-32 text-white text-muted mb-0"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ------------------------------------------ -->
          <div class="col-sm-4 col-md-4 col-lg-4 mt-2">
            <div class="card shadow" :class="{ overdue: overdueTotal > 0 }">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col">
                    <span class="h2 mb-0">{{ overdueTotal | currency }}</span>
                    <h5 class="text-muted mb-2 mt-2">Overdue</h5>
                  </div>
                  <div class="col-auto">
                    <span class="fe fe-32 fe-loader text-muted mb-0"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="card">
              <img class="card-img-top" src="holder.js/100x180/" alt="" />
              <div class="card-body">
                <h4 class="card-title">Payment History</h4>
                <div class="table-responsive">
                  <table class="table table-striped table-inverse">
                    <thead class="thead-inverse">
                      <tr>
                        <th>TRS ID</th>
                        <th>Code</th>
                        <th>Due-Date</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(pay, index) in payments" :key="index">
                        <td scope="row">{{ pay.id }}</td>
                        <td>{{ pay.payable.batch.batch_code | uppercase }}</td>
                        <td>{{ pay.due_date | pdate }}</td>
                        <td>{{ pay.type | uppercase }}</td>

                        <td v-if="pay.status == 'pending'">
                          <span
                            class="badge badge-warning"
                            style="font-size: 12px"
                          >
                            {{ pay.status | uppercase }}
                          </span>
                        </td>
                        <td v-if="pay.status == 'paid'">
                          <span
                            class="badge badge-success"
                            style="font-size: 12px"
                          >
                            {{ pay.status | uppercase }}
                          </span>
                        </td>
                        <td v-if="pay.status == 'overdue'">
                          <span
                            class="badge badge-danger"
                            style="font-size: 12px"
                          >
                            {{ pay.status | uppercase }}
                          </span>
                        </td>
                        <td>{{ pay.amount | currency }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- .row -->
        <!-- ================================ -->
        <div class="row mt-4">
          <div class="col-sm-8 col-md-8 col-lg-8">
            <div class="card shadow">
              <div class="card-header">
                <strong class="card-title">Up Coming Sessions</strong>
              </div>
              <div class="card-body">
                <div class="list-group list-group-flush my-n3">
                  <div class="list-group-item">
                    <div
                      v-for="(meet, index) in liveSession"
                      :key="index"
                      class="row align-items-center my-2"
                    >
                      <div class="col-3 col-md-2">
                        <!-- fe icon -->
                        <div class="circle circle-md bg-secondary">
                          <span class="fe fe-cast fe-16 text-white"></span>
                        </div>
                      </div>
                      <div class="col">
                        <strong>{{ meet.zoom_response.topic }}</strong>
                        <div class="my-0 text-muted small">
                          {{ meet.start_time | meetingDateTime }}
                        </div>
                      </div>
                      <div class="col-auto">
                        <button class="btn btn-primary">Join</button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- / .list-group -->
              </div>
              <!-- / .card-body -->
            </div>
          </div>
          <!-- ============== -->
          <div class="col-sm-4 col-md-4 col-lg-4">
            <div class="card shadow">
              <div class="card-header">
                <strong class="card-title">Recently Purchased Courses</strong>
                <a class="float-right small text-muted" href="">View all</a>
              </div>
              <div class="card-body">
                <div class="list-group list-group-flush my-n3">
                  <div class="list-group-item">
                    <div
                      v-for="(course, index) in CourseData"
                      :key="index"
                      class="row mt-2"
                    >
                      <div>
                        <div class="col-sm col-md col-lg">
                          <strong>{{ course.course.name }}</strong>
                          <div class="my-0 text-muted small">
                            {{ course.code }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- / .list-group -->
              </div>
              <!-- / .card-body -->
            </div>
          </div>
        </div>
      </div>
      <!-- .col-12 -->
    </div>
  </div>
  <!-- .container-fluid -->
</template>

<script>
import mainApi from "../api/mainApi";
import swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  name: "Home",

  created() {
    this.getMyCourses();
    this.getStudentPayments();
    this.getTotalOverdue();
    this.getZoomMeetings();
    this.getTodaysSessionCount();
  },

  data() {
    return {
      CourseData: "",
      payments: "",
      overdueTotal: "",
      liveSession: {},
      liveSessionCount: 0,
    };
  },

  methods: {
    getMyCourses() {
      mainApi
        .get("/api/lms/getCourses", {
          params: {
            top: 5,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.CourseData = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getStudentPayments() {
      mainApi
        .get("api/lms/my-invoices", {
          params: {
            top: 5,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.payments = response.data;
          }
        })
        .catch((error) => {
          swal.fire({
            title: "Opps...",
            icon: "error",
            text: error.response.data.message,
          });
        });
    },
    getTotalOverdue() {
      mainApi
        .get("/api/lms/getTotalOverdue")
        .then((response) => {
          if (response.status == 200) {
            this.overdueTotal = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getZoomMeetings() {
      mainApi
        .get("/api/lms/getStudentLiveSessions", {
          params: {
            top: 10,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.liveSession = response.data;
          }
        })
        .catch((error) => {
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        });
    },
    joinMeetingUrl(link) {
      window.open(link);
    },

    getTodaysSessionCount() {
      mainApi
        .get("/api/lms/getStudentLiveSessions", {
          params: {
            count: "today",
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.liveSessionCount = response.data;
          }
        })
        .catch((error) => {
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        });
    },
  },

  computed: {
    ...mapGetters("auth", { user: "getUser" }),

    totalCourseCount() {
      return this.CourseData.length;
    },
  },
};
</script>

<style scoped lang="scss">
.overdue {
  background-color: rgba(255, 0, 0, 0.081);
  color: white;
}
</style>
